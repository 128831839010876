<!-- Faq Section -->
<div id="AboutUs" class="section-block replicable-content bkg-grey-ultralight pb-40 pt-40">
    <div class="lxbrow">
        <div class="column width-12">
            <article class="post">
                <div class="lxbrow flex one-column-on-tablet">
                    <!-- <div class="column width-6 v-align-middle" style="background-color: #912626;">
                        <div class="post-media" style="background-color: #912626;">
                            <div class="thumbnail" style="background-color: #912626;" >
                                <img src="./assets/images/generic/project-7-1.webp" alt="project"  loading="lazy"/>
                                <div class="w-100 h-100" style="background-color: aqua; width: 100%; height: 100%;">&nbsp;</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="column width-12 v-align-middle">
                        <div>
                            <div class="post-content box large rounded bkg-white shadow">
                                <h2 class="post-title">{{ "Questions" | translate }}<br><span class="weight-semi-bold">{{ "OneCallAway" | translate }}</span></h2>
                                <!--div class="post-info">
                                    Launched <span class="post-date">25/01/18</span>, in <span class="post-category"><a href="#">Tech</a></span>
                                </div-->
                                <p>{{ "Available7Days" | translate }} </p>
                                <hr>
                                <ul class="list-unstyled list-horizontal list-funding flex left mb-10">
                                    <li class="amount-pledged center">
                                        <a href="tel:+17865779153">
                                            <span class="rounded-circle icon-circled rounded  large border-theme color-theme">
                                                <i class="fa fa-phone-square" aria-hidden="true" style="font-size: 40px;"></i>
                                            </span>
                                        </a>
                                        <h6 class="mb-0 pt-10 color-theme">{{ "callus" | translate }}</h6>
                                        <h6 class="mb-0 pt-10">1-786-577-9153</h6>
                                    </li>
                                    <li class="amount-funded center" >
                                        <a href="sms:+17865779153?body=Hello,%20ShippingSmooth.com!%20Could%20you%20please%20assist%20me%20today%3F">
                                            <span class="rounded-circle icon-circled rounded  large border-green color-green">
                                                <i class="fa fa-whatsapp" aria-hidden="true" style="font-size: 40px;"></i>
                                            </span>
                                        </a>
                                        <h6 class="mb-0 pt-10 color-green">SMS</h6>
                                        <h6 class="mb-0 pt-10">1-786-577-9153</h6>
                                    </li>
                                    <li class="days center">
                                        <a  (click)="openTawkChatTs()">
                                            <span class="rounded-circle icon-circled rounded large border-orange color-orange">
                                                <i class="fa fa-commenting-o" aria-hidden="true" style="font-size: 40px;"></i>
                                            </span>
                                        </a>
                                        <h6 class="mb-0 pt-10 color-orange">Chat</h6>
                                        <h6 class="mb-0 pt-10">Soporte</h6>
                                    </li>
                                </ul>
                                <!--a href="contact-style-one.html" class="button rounded large no-margins bkg-green bkg-hover-green color-white color-hover-white mb-0 fade-location">View Project</a-->
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>
<!-- Faq Section End -->