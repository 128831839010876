import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../Entities/Globals';
import { PromotionCodeBE } from '../../Entities/PromotionCodeBE';

@Component({
    selector: 'app-summaryExtend2',
    templateUrl: './summaryExtend2.component.html'
})

export class SummaryExtend2Component {
    // @Input() globalSummary: Globals;
    isMessage: boolean;
    Message: string;
    globals: any;
    valueDiscount: number = 0
    totalWithDiscount: number = 0
    objPromotion: PromotionCodeBE = new PromotionCodeBE();
    showLabel: boolean = false
    measure: string;

    constructor(private global: Globals, private translate: TranslateService) {
        this.isMessage = false
        let obj = Object.assign({}, this.global);
        this.globals = obj;
        this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue,2)
        this.globals.Order.Package.OriginalWeight = this.global.Order.Packages[0].OriginalWeight;
        if (this.globals.Order.Package.Pounds != undefined) {
            if (this.globals.Order.PromotionCode != null && this.globals.Order.PromotionCode.Response == 1) { 
                if (this.globals.Order.PromotionCode.Percent > 0) {
                    this.globals.Order.Discount = this.globals.Order.PromotionCode.Percent
                    this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
                    this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
                    this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
                } else {
                    this.globals.Order.Discount = 0
                    this.valueDiscount = this.globals.Order.PromotionCode.FixedValue
                    this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
                    this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
                }
            } else {
                this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
                this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
                this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
            }
        }

        if (this.globals.Order.Step == "3") {
            this.showLabel = true;
        }

        if (this.globals.Order.Step == "5") {
            this.showLabel = true;
            this.globals.Order.TotalValue = this.global.Order.CalculateResponse.Rate + this.global.Order.CalculateResponse.Insurance + this.global.Order.CalculateResponse.Taxes
            this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue,2)
           
            this.global.resetGeneralData();
        }
    this.measure = this.globals.Order.Package.Measure.slice(0,2);
    }

    validatePromoCode() {
        this.objPromotion.TotalValue = this.globals.Order.CalculateResponse.Rate;
        this.global.executeMethod("PromotionCode_SelectCommand", { promotion: this.objPromotion }, (x) => {
            this.objPromotion = x.PromotionCode_SelectCommandResult
            if (this.objPromotion.Response == 1) {
                this.isMessage = false
                if (this.objPromotion.Percent > 0) {
                    this.globals.Order.Discount = this.objPromotion.Percent
                    this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
                    this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
                    this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
                } else {
                    this.globals.Order.Discount = 0
                    this.valueDiscount = this.objPromotion.FixedValue
                    this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
                    this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
                }
                this.global.Order.PromotionCode = this.objPromotion
                this.global.saveData()
            } else if (this.objPromotion.Response == -1) {
                this.isMessage = true
                this.Message = this.translate.instant("CodeInvalid");
            } else if (this.objPromotion.Response == -100) {
                this.isMessage = true
                this.Message = this.translate.instant("CodeExpired");
            } else if (this.objPromotion.Response == -200) {
                this.isMessage = true
                this.Message = this.translate.instant("CodeMaxUse");
            }
        });
    }
}
