<!-- Accordions Button Nav -->
<div class="section-block replicable-content pt-0 pb-0">
  <div class="row">
    <div class="column width-12 left">
      <h2 class="weight-xbold pb-10">
        <span class="feature-icon icon-address large"></span>
        {{ "NextSteps" | translate }}
      </h2>
      <label class="text-medium">
        <ul
          class="breadcrumb inline-block mb-0 center clear-float-on-mobile"
          style="background-color: #fff"
        >
          <ng-container *ngFor="let i of 7 | times">
            <li *ngIf="i <= actualStep" class="color-green text-large">
              <span
                *ngIf="i <= actualStep"
                class="icon-check xsmall color-green"
                style="margin: 7px"
              ></span
              >{{ "WhatDoIDoNowStep" + (i + 1) | translate }}
            </li>
            <li *ngIf="i > actualStep + 1">
              {{ "WhatDoIDoNowStep" + (i + 1) | translate }}
            </li>
            <li *ngIf="i == actualStep + 1 && !smoothScroll">
              <span class="cursorPointer color-link" (click)="goToNextStep()"
                ><strong>{{
                  "WhatDoIDoNowStep" + (i + 1) | translate
                }}</strong></span
              >
            </li>
            <li *ngIf="i == actualStep + 1 && smoothScroll">
              <a class="cursorPointer scroll-link">{{
                "WhatDoIDoNowStep" + (i + 1) | translate
              }}</a>
            </li>
            <!-- <li *ngIf="i == (actualStep+1) && smoothScroll"><a class="cursorPointer scroll-link" [href]="smoothScroll">{{ 'WhatDoIDoNowStep' + (i+1) | translate }}</a></li>   -->
          </ng-container>
        </ul>
      </label>
    </div>
  </div>
</div>
<!-- Accordions Button Nav End -->

<!--modal USPS-->
<div
  class="modal fade pt-1 pl-2 paladinModal"
  id="uspsmodal"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-scrollable sinBordes bordesSuperiorBordeados"
  >
    <div class="modal-content sinBordes bordesSuperiorBordeados">
      <div
        class="modal-header pt-1 mt-0 sinBordes bordesSuperiorBordeados"
        style="background-color: #5faccf; height: 42px"
      >
        <div class="pt-1">
          <h4
            class="modal-header-title"
            style="color: #fff; padding-left: 20px; line-height: 25px"
          >
            {{ "WhatUSPS" | translate }}
          </h4>
        </div>
        <button
          type="button"
          class="color-hover-white pl-2 pr-1 m-0 pt-0 pb-1"
          data-dismiss="modal"
          aria-label="Close"
          style="
            background-color: #5faccf;
            border-color: #5faccf;
            color: #cfe6f0;
          "
        >
          <span aria-hidden="true" style="font-size: 20px; font-weight: 500"
            >&times;</span
          >
        </button>
      </div>
      <div class="modal-body paladinContenidoModal">
        <div
          class="column width-12 pt-0 contenidoModal"
          style="text-align: justify"
        >
          {{ "WhatUSPSDescription" | translate }}
          <br />
          <br />
          <div>
            <img src="./assets/images/USPS/WhatUSPSImg1.png" width="120%"  loading="lazy"/>
            <img src="./assets/images/USPS/WhatUSPSImg2.png" width="120%"  loading="lazy"/>
          </div>
        </div>
      </div>
      <div class="modal-footer sinBordes">
        <div class="lxbrow">
          <div class="column width-12 center pb-4 pl-4 pr-4 pt-2">
            <input
              type="submit"
              value="OK"
              data-dismiss="modal"
              class="form-submit button rounded medium bkg-green bkg-hover-theme bkg-focus-green color-white color-hover-white no-margins"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
