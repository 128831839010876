

<div class="cart-overview">
    <table class="table non-responsive rounded large mb-50">

        <tbody>

            <tr class="cart-item">
                <td class="product-details" colspan="2">
                    <h3 class="pt-30"><span class="icon-shopping-cart"></span> Resume de costos</h3>
                </td>
            </tr>
            <tr class="cart-item">
                <td class="product-details" colspan="2">
                
                        <span class="product-description">{{globals.Order.Package.ZipOrigination}} &rarr; 
                                {{ globals.Order.Beneficiary.CityBE.Name
                                }} - {{ globals.Order.Beneficiary.CityBE.CountryBE.Name }} </span>
                                                
                        <span class="label rounded bkg-orange-light color-white">{{'EntregaDomicilio' | translate}}</span> 
                </td>
            </tr>
            <tr class="cart-item">
                <td class="product-details">
                    <span>
                        <span>
                            <span class="product-title">{{'Transporte' | translate}} </span>
                            <span class="product-description">
                                    <em>{{ 'Weight' | translate }}: {{ this.globals.Order.Package.OriginalWeight }} {{ globals.Order.Package.MeasureWeight }}
                                    </em>
                                    <em>
                                        {{ 'Height' | translate }}: {{ globals.Order.Package.Height }} {{measure}}
                                    </em>
                                    <em>
                                        {{ 'Width' | translate }}: {{ globals.Order.Package.Width }} {{measure}}
                                    </em>
                                    <em>
                                        {{ 'Long' | translate }}: {{ globals.Order.Package.Length }} {{measure}}
                                    </em>
                                </span>
                        </span>
                    </span>
                </td>
                <td class="product-subtotal right">
                    <span class="amount">${{ globals.Order.CalculateResponse.Rate }}</span>
                    
                </td>
            </tr>

            <tr class="cart-item">
                <td class="product-details">
                    <span>
                        <span>
                            <span class="product-title">{{'Taxes' | translate}}</span>
                            <span class="product-description"><em>{{'DeclaredValue' | translate}} ${{ globals.Order.TotalTaxes }}</em></span>
                        </span>
                    </span>
                </td>
                <td class="product-subtotal right">
                    <span class="amount">${{ globals.Order.CalculateResponse.Taxes }}</span>
                </td>
            </tr>
    
            <!--tr class="cart-item">
                <td class="product-details">
                    
                    <span>
                        <span>
                            <span class="product-title">{{'Insurance' | translate }}</span>
                            <span class="product-description"><em>{{'InsuredValue' | translate}} ${{ globals.Order.TotalInsurance }}</em></span>
                        </span>
                    </span>

                </td>
                <td class="product-subtotal right">
                    <span class="amount">${{ globals.Order.CalculateResponse.Insurance }}</span>
                </td>
            </tr-->


            <!--tr class="cart-order-total right">
                <td colspan="2">
                    {{'Total' | translate}}:<span class="amount">${{ globals.Order.TotalValue }}</span>
                </td>
            </tr-->
        </tbody>
    </table>
</div>

