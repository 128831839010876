import { CityBE } from "./CityBE";


export class ProviderBE {

  constructor() {
    this.CityBE = new CityBE();
  }

  Id: number;
  CityBE = new CityBE();
  Name: string;
  Code: string;
  ComercialName: string;
  Address: string;
  Email: string;
  Phone: string;
  MobilePhone: string;
  Fax: string;
  Contact: string;
  Zip: string;
  IsLocal: boolean;
  IsForeign: number;
  Created: Date;
  IsEnabled: boolean;
  Imp: string;
  LabelTo: string;
  
}