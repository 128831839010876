import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs-compat/Subject';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  EElenguaje$ = new EventEmitter<string>();
  Slenguaje$: Subject<string> = new Subject<string>();
  constructor(private translate: TranslateService) { }
  getLenguaje$(): Observable<string> {
    return this.Slenguaje$.asObservable();
  }
  setLenguaje(len: string) {
    sessionStorage.setItem('lenguaje', len);
    this.translate.use(len);
  }
  getLenguaje() {
    return sessionStorage.getItem('lenguaje');
  }
}
