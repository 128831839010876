import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs-compat/Subject';
import { OrderBE } from '../Entities/OrderBE';
import { SeccionService } from './seccion.service';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class OrdenService {
  OrderBEPrincipal$: Subject<OrderBE> = new Subject<OrderBE>();
  UrlApi:string="";
  FBAppIdVer: string= "";

  constructor(private httpClient:HttpClient
    ,private config: AppConfig //ARCHIVO CONFIGURACION APP
    ,private seccionService: SeccionService //SERVICIO DE ALMACENAMIENTO LOCAL
    ) {
      this.FBAppIdVer = this.config.GetConfig('LetsboxitAPPIdVer');
      this.limpiarCache();
   }

  getOrder$(): Observable<OrderBE> {
    return this.OrderBEPrincipal$.asObservable();
  }

  get isOrder() {
    if (sessionStorage.getItem('isOrder') == 'true') {
      return true;
    } else {
       return false; 
      }
  }

  setIsOrder(estado: boolean) {
    if (estado) {
      sessionStorage.setItem('isOrder', 'true');
    } else sessionStorage.setItem('isOrder', 'false');
  }

  public limpiarCache(){
    if (sessionStorage.getItem("Order" + this.FBAppIdVer) == null) {
      this.seccionService.setJsonValue("Order" + this.FBAppIdVer, JSON.stringify(new OrderBE()));
      this.setIsOrder(false);
    } 
  }

  OrderOUT() {
    sessionStorage.removeItem('isOrder');
    sessionStorage.removeItem("Order" + this.FBAppIdVer);
    this.setIsOrder(false);
    this.OrderBEPrincipal$.next(new OrderBE());
  }

  setInfoOrder(Order: string) {
    this.seccionService.setJsonValue("Order" + this.FBAppIdVer, Order);
    //sessionStorage.setItem('Order', Order);
  }

  getInfoOrder() {
    return this.seccionService.getJsonValue("Order" + this.FBAppIdVer);//sessionStorage.getItem('Order');
  }
}
