import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs-compat/Subject';
//import { Usuario } from '../clase/usuario';
//import * as CryptoJS from 'crypto-js';  
import { CustomerBE } from '../Entities/CustomerBE';
import { AppConfig } from '../../app.config';
import { Seccion } from '../Entities/Seccion';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  CustomerBEPrincipal$: Subject<CustomerBE> = new Subject<CustomerBE>();
  UrlApi:string="";
  constructor(private httpClient:HttpClient,
    private config: AppConfig, //ARCHIVO CONFIGURACION
    private localService: LocalService //SERVICIO DE ALMACENAMIENTO LOCAL
    ) {
    this.config = config;
    this.UrlApi = this.config.GetConfig('UrlApi');
   }

  getCustomer$(): Observable<CustomerBE> {
    return this.CustomerBEPrincipal$.asObservable();
  }

  get isLogin() {
    if (sessionStorage.getItem('islogin') == 'true') {
      return true;
    } else {
       return this.getIsLocal(); 
      }
  }

  setIsLogin(estado: boolean) {
    if (estado) {
      sessionStorage.setItem('islogin', 'true');
    } else sessionStorage.setItem('islogin', 'false');
  }

  // consultarEstado() {
  //   var json;
  //   if (sessionStorage.getItem('islogin') == 'true') {
  //     json = { "estado": true };
  //   } else json = { "estado": false };
  //   return json;
  // }

  loginOUT() {
    sessionStorage.removeItem('islogin');
    sessionStorage.removeItem('customer');
    sessionStorage.removeItem('seccion');
    sessionStorage.clear();
    this.LocalOut();
    this.setIsLogin(false);
    this.CustomerBEPrincipal$.next(new CustomerBE());
  }

  setInfoCustomer(customer: string) {
    sessionStorage.setItem('customer', customer);
    this.setCrearSeccion();
  }

  getInfoUser() {
    return sessionStorage.getItem('customer');
  }

  getInfoSeccion() {
    return sessionStorage.getItem('seccion');
  }

  executeMethod(nameMethod,objData):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post(this.UrlApi + nameMethod, JSON.stringify(objData), { headers: headers });
  }
  
  setCrearSeccion(){
    sessionStorage.setItem('seccion', JSON.stringify(new Seccion()));
  }

  setLocal(){
    this.localService.setJsonValue('seccion',sessionStorage.getItem('seccion'));
    this.localService.setJsonValue('customer',sessionStorage.getItem('customer'));
    this.localService.setJsonValue('islogin',sessionStorage.getItem('islogin'));
  }

  getLocal(){
    sessionStorage.setItem('seccion', this.localService.getJsonValue('seccion'));
    sessionStorage.setItem('customer', this.localService.getJsonValue('customer'));
    sessionStorage.setItem('islogin', this.localService.getJsonValue('islogin'));
  }
  getIsLocal(){
    if (this.localService.getJsonValue('islogin')=='true'){
      this.getLocal();
      return true;
    } else return false;
  }
  LocalOut(){
    this.localService.clearToken('seccion');
    this.localService.clearToken('customer');
    this.localService.clearToken('islogin');
    //this.localService.clearToken();
  }
}