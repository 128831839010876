import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'Main', pathMatch: 'full' },
  { path: 'Main', loadChildren: () => import('./@pages/main/main.module').then(m => m.MainModule)},
  //{ path: 'Faq', loadChildren: () => import('./@pages/faq/faq.module').then(m => m.FaqModule) },
  { path: 'Privacy', loadChildren: () => import('./@pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'Terms', loadChildren: () => import('./@pages/terms/terms.module').then(m => m.TermsModule) },
  { path: 'Restrictions', loadChildren: () => import('./@pages/restrictions/restrictions.module').then(m => m.RestrictionsModule) },
  { path: 'Tracking', loadChildren: () => import('./@pages/tracking/tracking.module').then(m => m.TrackingModule)},
  { path: 'Carriers' , loadChildren: () => import('./@pages/carriers/carriers.module').then(m => m.CarriersModule)},
  { path: 'Calc', loadChildren: () => import('./@pages/calc/calc.module').then(m => m.CalcModule)},
  //verificar cuando se llama
  { path: 'CalcShipping', loadChildren: () => import('./@pages/calc/calc.module').then(m => m.CalcModule)},
  { path: 'Total', loadChildren: () => import('./@pages/total/total.module').then(m => m.TotalModule)},
  { path: 'Beneficiary', loadChildren: () => import('./@pages/beneficiary/beneficiary.module').then(m => m.BeneficiaryModule)},
  { path: 'CreditCheckout', loadChildren: () => import('./@pages/credit-checkout/credit-checkout.module').then(m => m.CreditCheckoutModule)},
  { path: 'mp/:ShipmentType', loadChildren: () => import('./@pages/credit-checkout/credit-checkout.module').then(m => m.CreditCheckoutModule)},
  { path: 'Confirmation', loadChildren: () => import('./@pages/confirmation/confirmation.module').then(m => m.ConfirmationModule)},
  { path: 'Login', loadChildren: () => import('./@pages/login/login.module').then(m => m.LoginModule)},
  { path: 'Register', loadChildren: () => import('./@pages/register/register.module').then(m => m.RegisterModule)},
  { path: 'RecoveryPassword', loadChildren: () => import('./@pages/recovery-password/recovery-password.module').then(m => m.RecoveryPasswordModule)},
  { path: 'Mobilepay', loadChildren: () => import('./@pages/mobilepay/mobilepay.module').then(m => m.MobilepayModule)},
  { path: 'Profile', canActivate:[AuthGuard], loadChildren: () => import('./@pages/profile/profile.module').then(m => m.ProfileModule)},
  { path: 'Recent', canActivate:[AuthGuard], loadChildren: () => import('./@pages/recent/recent.module').then(m => m.RecentModule)},
  { path: 'Checkout', canActivate:[AuthGuard], loadChildren: () => import('./@pages/checkout/checkout.module').then(m => m.CheckoutModule)},
  { path: 'Detail/:Id', canActivate:[AuthGuard], loadChildren: () => import('./@pages/detail/detail.module').then(m => m.DetailModule)}, 

  // { path: 'Prices', component: PricesComponent , canActivate:[AuthGuard]},
  // { path: 'Insurance', component: lastInsuranceComponent , canActivate:[AuthGuard]},
  // { path: 'Taxes', component: TaxesComponent , canActivate:[AuthGuard]},
  // { path: 'Shipping', component: ShippingCostComponent , canActivate:[AuthGuard]},
  
  { path: 'mp', redirectTo: 'Mobilepay', pathMatch: 'full' },
  { path: '**', redirectTo: 'Main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    scrollPositionRestoration: "enabled"
   })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
