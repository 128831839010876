import { Component, ElementRef, Input } from '@angular/core';
import { Globals } from '../../Entities/Globals';

declare function openTawkChat(): any;

@Component({
    selector: 'app-call-write-us',
    templateUrl: './callWriteUs.component.html'
})

export class CallWriteUsComponent {

    @Input()title;
    @Input()type; //1.Main 2.Simple

    constructor(private elementRef: ElementRef, private globals: Globals) {

    }

    ngOnInit() {
    }

    openTawkChatTs() {
        openTawkChat();
    }

}