import { CustomerBE } from "./CustomerBE";
import { BeneficiaryBE } from "./BeneficiaryBE";
import { ProviderBE } from "./ProviderBE";
import { PackageBE } from "./PackageBE";
import { AdditionalInfoBE } from "./AdditionalInfoBE";
import { CalculateResponseBE } from "./CalculateResponseBE";
import { PackageOrderBE } from "./PackageOrderBE";
import { CustomerCreditCardBE } from "./CustomerCreditCardBE";
import { LanguageBE } from "./LanguageBE";
import { CityBE } from "./CityBE";
import { OrderDetailBE } from "./OrderDetailBE";
import { CustomResponseBE } from "./CustomResponseBE";
import { PromotionCodeBE } from "./PromotionCodeBE";
import { ContentsBE } from "./contents-be";
import { CategorySelectBE } from "./category-select-be";

export class ZipCodeBE
    {
        ZipCode:string="";
        ZipCity:string="";
        ZipState:string="";
        zipState2 : string = "";
        ResponseCode:any=0;
    }

export class IdContentBE
    {
        IdCat:any="";
        Declare:number;
    }
    
export class OrderBE {
    constructor(){
        this.Customer = new CustomerBE();
        this.Beneficiary = new BeneficiaryBE()
        this.LocalProvider = new ProviderBE();
        this.ForeignProvider = new ProviderBE();
        this.Package = new PackageBE();
        this.CustomerCreditCard = new CustomerCreditCardBE();
        this.CustomerCreditCard.Customer = new CustomerBE();
        this.Language = new LanguageBE();
        this.AdditionalInfo = new AdditionalInfoBE();
        this.City = new CityBE();
        this.OrderDetail = new OrderDetailBE();
        this.CustomResponse = new CustomResponseBE()
        this.PromotionCode = new PromotionCodeBE();
        this.SKYPREFIXNUMBER = "";
        this.SKYBILLNUMBER = "";
        this.lstIdContents = "";
        this.lstContentsEsp=[];
        this.bandItems = false;
    }
    Id: number=0;
    Hash: string;
    ReceiptNumber: string;
    LocalProvider: ProviderBE;
    ForeignProvider: ProviderBE;
    Customer = new CustomerBE();
    Beneficiary = new BeneficiaryBE();
    TotalWeight: number;
    DeclaredValue: number = 0;
    TotalInsurance: number;
    TotalTaxes: number;
    TotalValue: number;
    TotalFreight: number;
    InsuredValue: number;
    Package: PackageBE;
    Carrier : Carrier[];
    CalculateResponse: CalculateResponseBE;
    Packages: PackageOrderBE[];
    CustomerCreditCard: CustomerCreditCardBE;
    Language: LanguageBE;
    AdditionalInfo: AdditionalInfoBE;
    City: CityBE;
    Tracking: string;
    OrderDetail: OrderDetailBE;
    isCheckDocuments: boolean
    CustomResponse: CustomResponseBE;
    Discount: number;
    Step:String;
    PromotionCode: PromotionCodeBE;
    PaymentMethodTotal: String;
    IsInProccess: boolean
    isCustomerShipping: boolean = false;
    USPSRate: number;
    isPaid: boolean;
    Gclid: string;
    ZipOrigination:ZipCodeBE=new ZipCodeBE();
    ZipDestination:ZipCodeBE=new ZipCodeBE();
    Created: string;
    // lstContents: ContentsBE[] = [];
    SKYPREFIXNUMBER: string="";
    SKYBILLNUMBER: string="";
    lstContents:CategorySelectBE[] = [];
    lstIdContents:string="";
    lstContentsEsp:string[]=[];
    nationalOrder : boolean;
    SelectCarrier : Carrier;
    bandItems : boolean;
    ContentsType : string;
}
export interface Carrier {
    ApiName:            string;
    Id:                 string;
    DeliveryDays:       string;
    Name:               string;
    Rate:               number;
    ServiceDescription: string;
    ServiceType:        string;
    RateOriginal:       number;
}

export enum ContentsType {
    gift = 1,
    merchandise,
    returned_goods,
    documents,
    sample,
    other,
}
