import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, UntypedFormControl } from '@angular/forms';
import { Globals } from '../../Entities/Globals';



declare var $: any;

@Component({
  selector: 'app-what-do',
  templateUrl: './what-do.component.html'
})
export class WhatDoComponent implements OnInit {

  @Input() actualStep;
  @Input() smoothScroll;
  @Output() nextStepEvent = new EventEmitter<string>();

  selectSearchControl = new UntypedFormControl('', Validators.required);
  public country: string;

  constructor(public globals: Globals) {

  }

  ngOnInit() {

  }

  goToNextStep() {
    this.nextStepEvent.emit(null);
  }
}


