import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  constructor(private router: Router) { }

  pageview() {

    let actualRoute = this.getActualRoute();
    

    this.getLastRoute(lastRoute => {
      if (lastRoute != actualRoute) {
        this.getGclid(gclid => {
          //se debe reigistra la pagina

          // (<any>window).analytics.page(actualRoute,
          //   {
          //     path: actualRoute,
          //     segmentCodeVersion: "12",
          //     gclid: gclid,
          //     cookies: document.cookie,
          //     fullUrl: location.href
          //   });
        });

        this.setLastRoute();

      }
    });

  }

  getLastRoute(returnData) {
    returnData(localStorage.getItem('lastRoute'));
  }

  setLastRoute() {
    let actualRoute = this.getActualRoute();
    localStorage.setItem('lastRoute', actualRoute);
  }

  // conversion(receiptNumber) {
  //   (<any>window).analytics.track("conversion", {
  //     'send_to': 'AW-1036731483/MAgHCPmHl8ABENuIre4D',
  //     'transaction_id': receiptNumber
  //   });
  // }

  // identify(order, lstOrders) {
  //   try {
  //     (<any>window).analytics.identify(order.Customer.Email, {
  //       customerData: order.Customer,
  //       shippings: lstOrders.length
  //     });
  //   } catch {
      
  //   }
  // }

  getActualRoute() {
    let route = this.router.url;
    return route;
  }

  //GOOGLE ADS

  setGclidFromURL() {
    //let urlTree = this.router.parseUrl(this.getActualRoute());
    try {
      let route = location.href;
     // console.log(location.href);
      route = route.split('gclid')[1];
      route = route.split('#')[0];
      let gclid = route.split('=')[1];

      if (gclid != undefined || gclid != null) {
        localStorage.setItem('gclid', gclid);
      }

    } catch {
      console.log('')
    }



  }

  getGclid(returnData) {
    let gclid = localStorage.getItem('gclid') || null;
    returnData(gclid);
  }

  setGclid() {
    try {
      let cookies = document.cookie;
      let cookiesArray = cookies.split(";");
      let cookie = "";

      cookiesArray.forEach(item => {
        if (item.includes("_gcl_aw") || item.includes("_gac_UA")) {
          cookie = item;
        }
      });

      let gclid = cookie.split("=")[1].split(".")[2];

      if (gclid != undefined || gclid != null) {
        localStorage.setItem('gclid', gclid);

      }
    } catch {
      console.log('')
    }


  }

}
