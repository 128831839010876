<!--Navbar  -->
<div class="col-12 bordeGris" style="background-color: #fff;">
    <div class="container p-0">
        <nav class="md-0 navbar navbar-expand-lg navbar-light" style="height: 80px; background-color: #ffffff; color: #000; ">
            <div style=" height:40px;  width: 140px; ">
                <a  [routerLink]="['Main']" class="  p-0 m-0"  >
                    <img src="./assets/images/Logo-SS-01.svg?20220912" class="p-0 m-0" alt="letsbox"  loading="lazy"/>
                </a>
            </div>
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-333"
                (click)="abrirMenu()"
                aria-controls="navbarSupportedContent-333" aria-expanded="false" aria-label="Toggle navigation" id="botonMenu" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent-333" >
                <ul class="navbar-nav ml-auto nav-flex-icons  linkMenu">
                    <li class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a [routerLink]="['Main']" class="btn linkMenu dropdown-item">
                            {{'NewSend' | translate}}</a>
                    </li>
                    <li class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a class="btn linkMenu dropdown-item" (click)="moverseElemento('HowItWork')">
                            {{'HowItWork' | translate}}</a>
                    </li>
                    <li class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a class="btn linkMenu dropdown-item" (click)="moverseElemento('AboutUs')">
                            {{'AboutUs' | translate}}</a>
                    </li>
                    <li class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a [routerLink]="['Tracking']" class="btn linkMenu dropdown-item">
                            {{'Tracking' | translate}}</a>
                    </li>
                    <li class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a class="btn linkMenu dropdown-item" (click)="CambiarIdioma()">
                            <img *ngIf="lenguajeLocal=='es'" src="./assets/images/flags/1.svg" class="rounded-circle rounded " alt="es" width="26px" height="26px"  loading="lazy"/>
                            <img *ngIf="lenguajeLocal=='en'" src="./assets/images/flags/7.svg" class="rounded-circle rounded " alt="en" width="26px" height="26px"  loading="lazy"/>
                             {{'Language' | translate}}</a>
                    </li>
                    <li *ngIf="!isLogged" class="nav-item mx-auto my-auto" (click)="clickElemento()" >
                        <a [routerLink]="['Login']" class="btn linkMenu dropdown-item">
                            <i class="fa fa-lock fa-6"></i> {{'MyAccount' | translate}}</a>
                    </li>
                    <li *ngIf="isLogged" class="nav-item dropdown btn mx-auto my-auto" >
                        <a class="dropdown-toggle linkMenu dropdown-item" id="navbarDropdownMenuLink-4" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-user fa-6"></i> {{usuario.Name | uppercase}}</a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink-4">
                            <a [routerLink]="['Profile']" class="dropdown-item btn linkMenu" (click)="clickElemento()">
                                <i class="fa fa-user" aria-hidden="true"></i> {{'Profile' | translate}}
                            </a>
                            <a [routerLink]="['Recent']" class="dropdown-item btn linkMenu" (click)="clickElemento()">
                              <i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{'RecentSendings' | translate}}
                            </a>
                            <!-- (click)="isShipping()" -->
                            <a *ngIf="usuario.Role" [routerLink]="['CalcShipping']" class="dropdown-item btn linkMenu" (click)="clickElemento()">
                                <i class="fa fa-shopping-cart" aria-hidden="true"></i> {{'CreateNewSendCustomer' | translate}}
                            </a>
                            <a class="dropdown-item btn linkMenu" (click)="salir()" >
                              <i class="fa fa-sign-out" aria-hidden="true"></i> {{'Exit' | translate}}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>    
</div>
