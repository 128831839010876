import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs-compat/Subject';
import { CountryBE } from '../Entities/CountryBE';
import { CityBE } from '../Entities/CityBE';
import { AppConfig } from '../../app.config';
import { LocalService } from './local.service';

//const Strupdate="20220823";
@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  CountryBEPrincipal$: Subject<CountryBE> = new Subject<CountryBE>();
  CityBEPrincipal$: Subject<CityBE> = new Subject<CityBE>();
  FBAppIdVer: string= "";

  constructor( 
    private config: AppConfig //ARCHIVO CONFIGURACION APP
    ,private localService: LocalService //SERVICIO DE ALMACENAMIENTO LOCAL
    ) {
    this.FBAppIdVer = this.config.GetConfig('LetsboxitAPPIdVer');
    this.limpiarCache();
   }

  getCountryBE$(): Observable<CountryBE> {
    return this.CountryBEPrincipal$.asObservable();
  }

  getCityBE$(): Observable<CityBE> {
    return this.CityBEPrincipal$.asObservable();
  }

  isUpdate(pais: string) {
    if (localStorage.getItem('isUpdate'+pais) == this.FBAppIdVer) {
      return true;
    } else {
       return false; 
      }
  }

  get isCountryBE() {
    if (localStorage.getItem('isCountryBE' + this.FBAppIdVer ) == 'true') {
      return true;
    } else {
       return false; 
      }
  }

  setIsCountryBE(estado: boolean) {
    if (estado) {
      localStorage.setItem('isCountryBE' + this.FBAppIdVer, 'true');
    } else localStorage.setItem('isCountryBE' + this.FBAppIdVer, 'false');
  }

  public limpiarCache(){
    if (localStorage.getItem("CountryBE" + this.FBAppIdVer) == null) {
      this.setInfoCountryBE("[]");
      this.setIsCountryBE(false);
    } 
  }

  CountryOUT() {
    localStorage.removeItem('isCountryBE' + this.FBAppIdVer);
    localStorage.removeItem("CountryBE" + this.FBAppIdVer);
    this.setIsCountryBE(false);
    this.CountryBEPrincipal$.next(new CountryBE());
  }

  setInfoCountryBE(CountryBE: string) {
    this.localService.setJsonValue("CountryBE" + this.FBAppIdVer, CountryBE);
  }

  getInfoCountryBE() {
    return this.localService.getJsonValue("CountryBE" + this.FBAppIdVer)
  }

  setInfoCityBE(pais: string,ListaCityBE: string) {
    localStorage.setItem(pais, ListaCityBE);
    localStorage.setItem('isUpdate'+pais,this.FBAppIdVer)
  }

  getInfoCityBE(pais: string) {
    return this.isUpdate(pais)?localStorage.getItem(pais):null;
  }
} 