import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  @Input() modal:boolean=false;
  constructor( ) { }

  ngOnInit(): void {
      
  }

}
