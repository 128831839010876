import { CountryBE } from "./CountryBE";


export class CityBE {
    constructor(){
        this.CountryBE = new CountryBE();
    }
    Id: number;
    Name: string = '';
    CountryBE: CountryBE;
    State: string ='';
    NameCompleto: string ='';
    Code: string ='';
    IsEnabled:boolean=true;
    trimState : string = '';
}