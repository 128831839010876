import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http: HttpClient) { }

  checkLocalClientIP() {
    let localCLientIP = this.getLocalClientIP();

    if (!localCLientIP) {
      this.setLocalClientIP();
    }
  }

  private setLocalClientIP() {
    this.getClientIP(data => {
      localStorage.setItem('localClientIP', data.ip);
    })
  }

  getLocalClientIP() {
    return localStorage.getItem('localClientIP');
  }

  public getClientIP(returnData) {
    let Url = `https://api.ipify.org?format=json`;

    this.http.get(Url)
      .subscribe(data => {
        returnData(data);
      },
        err => {
          console.log(err)
        }
      );
  }
}
