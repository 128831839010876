import { CountryBE } from "./CountryBE";


export class InsuranceResticionsBE {
    constructor(){
        this.CountryBE = new CountryBE();
    }
    InsuranceRestrictionId: number;
    CountryBE: CountryBE;
    MinValue: number;
    MaxValue: number;
    Message: String = '';
    
}