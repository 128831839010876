import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class ComplemtService {
  UrlApi:string="";
  constructor(private httpClient:HttpClient,
    private config: AppConfig, //ARCHIVO CONFIGURACION
    ) {
      this.config = config;
      this.UrlApi = this.config.GetConfig('UrlApi');
    }
  ///////////////////////////////
  IsNumR(elemento)
  {
    var Template = /^(\d{1,3}[.]){1,3}\d{2,2}$/i
    let valor;  
    if (elemento.length>0){
      valor=(Template.test(elemento)) ? 1 : 0 //Compara con el formato "Template" y si coincidevuelve verdadero si no devuelve falso
    }
    if (elemento.length==0){
      valor=1;
    }
    return valor;
  }
  ///////////////////////////////
  IsNum(elemento)
  {
    var Template = /^\d{1,}$/i 
    let valor;  
    if (elemento.length>0){
      valor=(Template.test(elemento)) ? 1 : 0 //Compara con el formato "Template" y si coincidevuelve verdadero si no devuelve falso
    }
    if (elemento.length==0){
      valor=1;
    }
    return valor;
  }
  ///////////////////////////////
  VerifNumR(elemento)
  {
    var Template1 = /^(\d*)([.]\d{2,2})$/i;
    var num;
    if (!this.IsNumR(elemento)){
      if (this.IsNum(elemento)){
        num = elemento.replace(/\./g,'');
        if(!isNaN(num)){
          if ( parseInt(num) <= 999&&parseInt(num)!=0)
            num += '000';
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/^[\.]/,'');
          elemento = num+".00";
        }
        return elemento;
      }else{
        if(Template1.test(elemento)){
          num = elemento.replace('.00','');
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/^[\.]/,'');
          elemento = num+".00"; 
          return elemento;
        }else
          return "0.00";
      }
    }else{
      return elemento;
    }
  }
  ///////////////////////////////////////////////////
  keypressVariado(evento,elemento,elementosig,bandPress:boolean=false)
  {
    var tecla;
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    if (tecla > 47 && tecla < 58) return true;
    if (tecla > 64 && tecla < 91) return true;
    if (tecla > 96 && tecla < 123) return true;
    if (tecla == 32 && elemento.length>1) return true;
    if (tecla == 0 || tecla == 8 || tecla == 44 || tecla == 45 || tecla == 46 || tecla == 47
      || tecla == 33 || tecla == 34 || tecla == 35 || tecla == 9) return true;
    if (tecla == 13)
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    return false;
  }
  ///////////////////////////////////////////////////
  keypressCorreo(evento,elemento,elementosig,bandPress:boolean=false)
  {
    var tecla;
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    if (tecla > 32 && tecla < 127) return true;
    if (tecla > 127 && tecla < 166) return true;
    //if (tecla == 32 && elemento.length>1) return true;
    if (tecla == 0 || tecla == 8 || tecla == 9 ) return true;
    if (tecla == 13)
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    return false;
  }

  keypressTexto(evento,elemento,elementosig,bandPress:boolean=false)
  {
    var tecla;
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    if (tecla > 64 && tecla < 91) return true;
    if (tecla > 96 && tecla < 123 ) return true;
    //if (tecla == 32 && elemento.length>1) return true;
    if (tecla == 0 || tecla == 8 || tecla == 9 || tecla == 32 ) return true;
    if (tecla == 13)
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    return false;
  }

  ///////////////////////////////////////////////////
  keypressDocumento(evento,elemento,elementoBusqueda,elementosig) {
    var tecla;
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    //console.log(tecla);
    if (tecla > 47 && tecla < 58) return true;
    if (tecla > 64 && tecla < 91) return true;
    if (tecla > 96 && tecla < 123) return true;
    if (tecla == 0 || tecla == 8 || tecla == 9 || tecla == 45) return true;

    if (tecla == 13) {// si es (espacio) o enter
      if (elemento!=''){
        document.getElementById(elementoBusqueda).click();
      }else
        document.getElementById(elementosig).focus();
    }
    return false;
  }

  ///////////////////////////////////////////////////
  keypressNumeros(evento,elemento,elementosig,bandPress:boolean=false)
  {
    var tecla;
    
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    if (tecla > 47 && tecla < 58) return true;
    if (tecla == 0 || tecla == 8 || tecla == 9) return true;
    if (tecla == 13) {// si es (espacio) o enter
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    }
    return false;
  }
  ///////////////////////////////////////////////////
  keypressNumerosReales(evento,elemento,elementosig,bandPress:boolean=false)
  {
    var tecla;
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    if (tecla > 47 && tecla < 58) return true;
    if (tecla == 0 || tecla == 8 || tecla == 9 || tecla == 46) return true;

    if (tecla == 13) {// si es (espacio) o enter
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    }
    return false;
  }
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  keypressDecimales(evento,elemento:string,elementosig,longitud,bandPress:boolean=false)
  {
    var tecla;
    
    if (navigator.appName == "Netscape") tecla = evento.which;
    else tecla = evento.keyCode;
    
    if (tecla == 0 || tecla == 8 || tecla == 9) return true;

    if (tecla == 13) {// si es (espacio) o enter
      if (bandPress){
        document.getElementById(elementosig).click();
      }else{
        document.getElementById(elementosig).focus();
      }
    }
    // console.log("elemento?.length>longitud",elemento?.length>longitud);
    // console.log("tecla",tecla);

    if(elemento!=null){
      if (elemento?.length>longitud) return false;
    }
   // console.log("tecla",tecla);
    if (tecla > 47 && tecla < 58) return true;

    return false;
  }
  ///////////////////////////////////////////////////
  isErrorCampoTexto(elemento:string,long=6){ 
   if(elemento==null){
    return true;
   }
    if (elemento==''||elemento.length<long){
      return true;
    }else return false;
  }

  isErrorCampoTextoOpcional(elemento:string,long=6){ 
    if(elemento==null){
     return false;
    }
     if (elemento!=''&&elemento.length<long){
       return true;
     }else return false;
   }

   isErrorNumeroReal(elemento){
    if(Number.isInteger(elemento)) return false;
    if (elemento==''||(this.setCurrency(elemento))<1){
      return true;
    }else return false;
  }


  isWarningNumeroReal(elemento,valor){
    if(Number.isInteger(elemento)) return false;
    if (this.setCurrency(elemento.toString())<(valor)&&elemento!=''){
      return true;
    }else return false;
  }

  setCurrency(cad:string){
    var Template1 = /^(\d*)([.]\d{2,2})$/i;
    var Template2 = /^((\d{1,3}[.]){1,3})(\d{2})$/i;
    var Template3 = /^(\d*)/i;
    if(Template1.test(cad)){
      return Number(cad);
    }
    if (cad==null||cad=="") return 0.00;

    cad = cad.toString().replace('.','');
    cad = cad.toString().replace(',','.');
    if(!isNaN(Number(cad))){
      return Number(cad);
    }
    return 0.00;
  }

  executeMethod(nameMethod,objData):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post(this.UrlApi + nameMethod, JSON.stringify(objData), { headers: headers });
  }
 
  convertirReal(valor:string){
    return parseFloat(valor);
  }

  mostrarReal(numero,digitos){
    var valor:number=parseFloat(numero);
    // console.log(valor.toFixed(digitos))
    // console.log(parseFloat(this.Depth))
    // console.log(this.Depth)
    // console.log(parseFloat(valor.toFixed(0)))
    // console.log((parseFloat(this.Depth)-parseInt(valor.toFixed(0)))>0)
     if ((parseFloat(numero)-parseInt(valor.toFixed(0)))>0){
      numero=valor.toFixed(digitos);
      //console.log(valor.toFixed(digitos));
     }
     return parseFloat(numero);    
  }

}