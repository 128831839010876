<!--
<app-head-mobile></app-head-mobile>


<div class="wrapper reveal-side-navigation">
    <div class="wrapper-inner">
        <app-header2></app-header2>

        Content
        <div class="content clearfix">
            Checkout
            <div class="section-block bkg-gradient-dark-night no-padding-top-responsive">
                <div class="row one-column-on-tablet">
                    <div class="column width-6 offset-3">
                        <div class="with-background no-padding-top shadow">
Billing Form -->
<!-- <ngx-loading
  [show]="ngxLoading"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading> --> 
<div class="billing-details">
    <div class="billing-form-container">
        <form class="billing-form" action="#" method="post" novalidate>
            <!-- Cart Overview and Totals -->
            <div class="row">
                <div class="column width-12 center">
                    <div class="border-grey">
                        <h3 class="pt-20 ">{{'AseguraTu' | translate}}</h3>
                        <h2 class="weight-semi-bold">{{'TanSolo' | translate}} ${{ globals.Order.CalculateResponse.Insurance }}</h2>
                        <span class="text-small">{{'CubreDanos' | translate}} ${{ globals.Order.TotalInsurance }}</span>
                        <div class="cart-overview">
                            <table class="table non-responsive rounded large mb-10">
                                <tbody>
                                    <tr class="cart-order-tax right bp-10">
                                        <!-- style="padding:5px;" -->
                                        <td class="center pt-2">
                                            <input type="text" class="form-element rounded small " name="declared value" placeholder="Valor asegurado" 
                                                tabindex="1"
                                                pattern="[0-9]*" [(ngModel)]="dblNewInsurance" style="width:110px;height: 36px;" required> &nbsp;
                                            <a (click)="recalculate()" class="button rounded small border-green bkg-hover-green color-white color-hover-white no-padding no-margin p-0"
                                                style="margin:0px;">&rarr;</a>
                                            &nbsp;&nbsp;&nbsp;
                                            <span class="amount">${{ globals.Order.CalculateResponse.Insurance }}</span>
                                        </td>
                                    </tr>
                                    <tr class="center">
                                        <td>
                                            <p class="text-small">{{'SubirImp' | translate}}</p>
                                            <div class="alert alert-danger" *ngIf="!hideMessage">
                                                {{ errorMessage }}
                                            </div>
                                            <div class="box rounded small " [ngClass]="{'success': insuranceAplied != null, 'warning': insuranceAplied == null}" *ngIf="!hideMessage3">
                                                <strong>{{ insuranceApliedMessage }}</strong>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Payment Method -->
                        <div class="row" [ngClass]="{'hidden': isDisabled}">
                            <div class="column width-12 center">
                                <a (click)="onDecline($event)" class="button medium rounded bkg-grey bkg-hover-black color-white color-hover-white scroll-link">{{'Declinar' | translate}}</a>
                                    &nbsp;
                                <a (click)="onAccept($event)" 
                                class="button medium rounded bkg-theme bkg-hover-green color-white color-hover-white scroll-link">
                                {{'Aceptar' | translate}}</a>
                                <!-- SubmitPayment -->
                                <!-- <a id="goToSubmitPayment" href="#" class="scroll-link"></a> -->
                            </div>
                        </div>
                        <div class="info center mb-10" *ngIf="!hideMessage2">{{ 'SendingPaymentBTN' | translate }}</div>
                        <!-- Payment Method End -->
                    </div>
                </div>
            </div>
            <!-- Cart Overview and Totals End -->
        </form>
    </div>
</div>
<!-- Billing Form End -->
<!--
                        </div>

                    </div>
                </div>
                Checkout End


            </div>

        </div>

         Content End
        <app-footer></app-footer>
    </div>
</div>
-->