import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
const SecureStorage = require('secure-web-storage');
//const CryptoJS  = require("crypto-js");
const SECRET_KEY = 'LB$S#$T#$%IT23NR#$R';
@Injectable()
export class StorageService {
    constructor() { }
    public secureStorage = new SecureStorage(localStorage, {
        // Encrypt the localstorage data
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);
            data = data.toString();
            return data;
        },
        // Decrypt the encrypted data
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        },
        hash: function hash(data) {
            return data;
        }
    });
    public secureStorageSeccion = new SecureStorage(sessionStorage, {
        // Encrypt the sessionStorage data
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);
            data = data.toString();
            return data;
        },
        // Decrypt the encrypted data
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        },
        hash: function hash(data) {
            return data;
        }
    });
}