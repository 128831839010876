import { Component } from '@angular/core';
import { Router } from '@angular/router';
import animateScrollTo from 'animated-scroll-to';
import { Globals } from '../../Entities/Globals';
import { VersionService } from '../../services/version.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
    version: string;
    constructor(private router: Router, private globals: Globals, private vs: VersionService) {
        this.version = vs.getVersion();
    }
    // goFaq() {
    //     this.globals.url = "index.html"
    //     this.router.navigated = false;
    //     this.router.navigate(['./Faq']);
    //     setTimeout(() => {
    //         this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
    //             this.router.navigate(['/Faq'], {
    //                 queryParams: { 'refresh': Date.now() }
    //             })
    //         );
    //     }, 500)
    // }
    //

    goMain(){
        //console.log("'"+this.router.url+"'");
        if (this.router.url != "/Main") {
            this.router.navigate(['Main']);
        }
    }

    irInicio(){
        animateScrollTo(10);
    }
    
    findPosition(obj) { 
        var currenttop = 0; 
        try{
        if (obj.offsetParent) { 
            do { 
                currenttop += obj.offsetTop; 
            } while ((obj = obj.offsetParent)); 
            return currenttop; 
        } 
        } catch(ex){
        return -1;
        }
    } 
        
    moverseElemento(elemento){
        let pos=this.findPosition( document.getElementById(elemento));
        if (pos>=0){
            if (pos>80){
            pos=pos-40;
            }
            animateScrollTo(pos);
        }else{
            if (this.router.url != "/Main") {
            this.router.navigate(['Main']);
            setTimeout(function(){ 
                pos=this.findPosition( document.getElementById(elemento));
                if (pos>=0){
                    pos=pos+80;
                    animateScrollTo(pos);
                }else{
                    console.log("ERROR: No se encontro el elemento en la pagina :"+ elemento);
                }
            }, 250); 
            }else console.log("ERROR: No se encontro el elemento en la pagina :"+ elemento);
        }
    }
}
