export class OrderDetailBE {
    CustomerFullName: string;
    CustomerAddress: String;
    CustomerAddress2: String;
    CustomerMobilePhone: String;
    CustomerZipCode: String;
    BeneficiaryFullName: String;
    BeneficiaryAddress: String;
    BeneficiaryMobilePhone: String;
    IsFixedPromoCode: boolean;
}