import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { Globals } from './shared/Entities/Globals';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { Interceptor } from './app.interceptor';
import { TokenStorage } from './token.storage';
import { ScriptService } from './shared/services/script.service';
import { VersionService } from './shared/services/version.service';
import { LogService } from './shared/services/log.service';
import { SegmentService } from './shared/services/segment.service';
import { LocalService } from './shared/services/local.service';
import { SeccionService } from './shared/services/seccion.service';
import { StorageService } from './shared/services/storage.service';
import { SharedModule } from './shared/shared.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=20221005');
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '4px',
      fullScreenBackdrop: true,
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    ToastrModule.forRoot()
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    ToastrModule,
    HttpClientModule,
    NgxLoadingModule,
  ],
  providers: [
    Globals,
    LocalService,
    SeccionService,
    StorageService,
    ScriptService,
    VersionService,
    LogService,
    SegmentService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: 'googleTagManagerId', useValue: 'GTM-PWP8WQ7' },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.Load(),
      deps: [AppConfig],
      multi: true,
    },
    TokenStorage,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
