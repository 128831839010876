import { Injectable } from "@angular/core"
import { StorageService } from "./storage.service";
@Injectable({
    providedIn: "root"
})
export class SeccionService {
    constructor(private storageService: StorageService
        ) { }
    // Set the json data to Seccion storage
    setJsonValue(key: string, value: any) {
        this.storageService.secureStorageSeccion.setItem(key, value);
    }
    // Get the json value from Seccion storage
    getJsonValue(key: string) {
        try {
        return this.storageService.secureStorageSeccion.getItem(key);
        } catch(er){
            this.setJsonValue(key,"")
        }
    }
    // Clear the Seccion storage
    clearToken(key:string) {
        return this.storageService.secureStorageSeccion.removeItem(key);
        //.clear();
    }
    clearTokenAll() {
        return this.storageService.secureStorageSeccion.clear();
    }
} 