<!-- <app-header [isSpecialHeader]="true"></app-header> -->

      <!-- "privacyPolicyFechaRev":
      "privacyPolicyTittle":
  
      "privacyPolicyTxt00":
      "privacyPolicyTxt001":
      "privacyPolicyTxt002":
      "privacyPolicyTxt01":
      "privacyPolicyTxt011":
      "privacyPolicyTxt0111":
      "privacyPolicyTxt0112":
      "privacyPolicyTxt0113":
      "privacyPolicyTxt0114":
      "privacyPolicyTxt0115":
      "privacyPolicyTxt0116":
      "privacyPolicyTxt0117":
      "privacyPolicyTxt0118":
      "privacyPolicyTxt012":
      "privacyPolicyTxt013":
      "privacyPolicyTxt02":
      "privacyPolicyTxt021":
      "privacyPolicyTxt03":
      "privacyPolicyTxt031":
      "privacyPolicyTxt032":
      "privacyPolicyTxt033":
      "privacyPolicyTxt0331":
      "privacyPolicyTxt0332":
      "privacyPolicyTxt0333":
      "privacyPolicyTxt0334":
      "privacyPolicyTxt0335":
      "privacyPolicyTxt0336":
      "privacyPolicyTxt0337":
      "privacyPolicyTxt0338":
      "privacyPolicyTxt0339":
      "privacyPolicyTxt03310":
      "privacyPolicyTxt03311":
      "privacyPolicyTxt03312":
      "privacyPolicyTxt03313":
      "privacyPolicyTxt03314":
      "privacyPolicyTxt03315":
      "privacyPolicyTxt034":
      "privacyPolicyTxt035":
      "privacyPolicyTxt036":
      "privacyPolicyTxt04":
      "privacyPolicyTxt041":
      "privacyPolicyTxt042":
      "privacyPolicyTxt05":
      "privacyPolicyTxt051":
      "privacyPolicyTxt052":
      "privacyPolicyTxt053":
      "privacyPolicyTxt06":
      "privacyPolicyTxt061":
      "privacyPolicyTxt07":
      "privacyPolicyTxt0701":
      "privacyPolicyTxt0702":
      "privacyPolicyTxt0703":
      "privacyPolicyTxt071":
      "privacyPolicyTxt072":
      "privacyPolicyTxt073":
      "privacyPolicyTxt074":
      "privacyPolicyTxt08":
      "privacyPolicyTxt081":
      "privacyPolicyTxt082":
      "privacyPolicyTxt083":
      "privacyPolicyTxt084":
      "privacyPolicyTxt085":
      "privacyPolicyTxt086":
      "privacyPolicyTxt09":
      "privacyPolicyTxt091":
      "privacyPolicyTxt092":
      "privacyPolicyTxt093":
      "privacyPolicyTxt10":
      "privacyPolicyTxt101":
      "privacyPolicyTxt11":
      "privacyPolicyTxt111":
      "privacyPolicyTxt112":
      "privacyPolicyTxt12":
      "privacyPolicyTxt121":
      "privacyPolicyTxt1211":
      "privacyPolicyTxt122":
      "privacyPolicyTxt1221":
      "privacyPolicyTxt13":
      "privacyPolicyTxt131":
      "privacyPolicyTxt14":
      "privacyPolicyTxt141":
      "privacyPolicyTxt142":
      "privacyPolicyTxt15":
      "privacyPolicyTxt151":
      "privacyPolicyTxt16":
      "privacyPolicyTxt161":
      "privacyPolicyTxt17":
      "privacyPolicyTxt171":
      "privacyPolicyTxt18":
      "privacyPolicyTxt181":
  
      "privacyPolicyTxt19":
      "privacyPolicyTxt191":
      "privacyPolicyTxt192":
      "privacyPolicyTxt193":
      "privacyPolicyTxt194":
      "privacyPolicyTxt20":
      "privacyPolicyTxt201":
      "privacyPolicyTxt21":
      "privacyPolicyTxt211":
      "privacyPolicyTxt22":
      "privacyPolicyTxt221":
      "privacyPolicyTxt23":
      "privacyPolicyTxt231":
      "privacyPolicyTxt24":
      "privacyPolicyTxt241": -->

     
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">

      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 >{{"privacyPolicyTxt00" | translate}}</h2>
      </div>

      <div class="column width-12 mb-30" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h4> {{'privacyPolicyTxt001' | translate}}</h4>
      </div>
      <div class="column width-12 mb-30" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h4> {{'privacyPolicyTxt002' | translate}}</h4>
      </div>
      <!-- <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"" | translate}}</li>
          </ul> 
      </div> -->

      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt01' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt011" | translate}}
                  <ul>
                      <li>{{"privacyPolicyTxt0111" | translate}}</li>
                      <li>{{"privacyPolicyTxt0112" | translate}}</li>
                      <li>{{"privacyPolicyTxt0113" | translate}}</li>
                      <li>{{"privacyPolicyTxt0114" | translate}}</li>
                      <li>{{"privacyPolicyTxt0115" | translate}}</li>
                      <li>{{"privacyPolicyTxt0116" | translate}}</li>
                      <li>{{"privacyPolicyTxt0117" | translate}}</li>
                      <li>{{"privacyPolicyTxt0118" | translate}}</li>
                  </ul>
              </li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt012" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt013" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt02' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt021" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt03' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt031" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt032" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt033" | translate}}
                  <ul>
                      <li>{{"privacyPolicyTxt0331" | translate}}</li>
                      <li>{{"privacyPolicyTxt0332" | translate}}</li>
                      <li>{{"privacyPolicyTxt0333" | translate}}</li>
                      <li>{{"privacyPolicyTxt0334" | translate}}</li>
                      <li>{{"privacyPolicyTxt0335" | translate}}</li>
                      <li>{{"privacyPolicyTxt0336" | translate}}</li>
                      <li>{{"privacyPolicyTxt0337" | translate}}</li>
                      <li>{{"privacyPolicyTxt0338" | translate}}</li>
                      <li>{{"privacyPolicyTxt0339" | translate}}</li>
                      <li>{{"privacyPolicyTxt03310" | translate}}</li>
                      <li>{{"privacyPolicyTxt03311" | translate}}</li>
                      <li>{{"privacyPolicyTxt03312" | translate}}</li>
                      <li>{{"privacyPolicyTxt03313" | translate}}</li>
                      <li>{{"privacyPolicyTxt03314" | translate}}</li>
                      <li>{{"privacyPolicyTxt03315" | translate}}</li>
                  </ul>
              </li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt034" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt035" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt036" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt04' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt041" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt042" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt05' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt051" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt052" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt053" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt06' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt061" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt07' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">
                  <ul>
                      <li>{{"privacyPolicyTxt0701" | translate}}</li>
                      <li>{{"privacyPolicyTxt0702" | translate}}</li>
                      <li>{{"privacyPolicyTxt0703" | translate}}</li>
                  </ul>
              </li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt071" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt072" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt073" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt074" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt08' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt081" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt082" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt083" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt084" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt085" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt086" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt09' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt091" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt092" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt093" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt10' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt101" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt11' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt111" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt112" | translate}}</li>
          </ul> 
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt12' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt121" | translate}}
                  <ul>
                      <li>{{"privacyPolicyTxt1211" | translate}}</li>
                  </ul>
              </li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt122" | translate}}
                  <ul>
                      <li>{{"privacyPolicyTxt1221" | translate}}</li>
                  </ul>
              </li>
          </ul> 
      </div>        
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt13' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt131" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt14' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt141" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt142" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt15' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt151" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt16' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt161" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt17' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt171" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt18' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt181" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt19' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt191" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt192" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt193" | translate}}</li>
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt194" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt20' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt201" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt21' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt211" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt22' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt221" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt23' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt231" | translate}}</li>
          </ul> 
      </div> 
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <h2 [ngClass]="{'contenidoModal':modal}"> {{'privacyPolicyTxt24' | translate}}</h2>
      </div>
      <div class="column width-12 mb-0" style="text-align: justify;" [ngClass]="{'contenidoModal':modal}">
          <ul >
              <li [ngClass]="{'contenidoModal':modal}"type="circle">{{"privacyPolicyTxt241" | translate}}</li>
          </ul> 
      </div> 

<!-- About Section End -->