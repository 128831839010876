import { Component, Input } from '@angular/core';
import { SegmentService } from '../../services/segment.service';

@Component({
  selector: 'app-restrictions',
  templateUrl: './restrictions.component.html',
  styleUrls: ['./restrictions.component.css'],
})
export class RestrictionsComponent {
  @Input() options: string = '';
  @Input() pais: string = '';
  @Input() modal: boolean = true;
  @Input() id: number = 0;

  constructor(private segmentService: SegmentService) {}
}
