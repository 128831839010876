<footer class="footer" class="footer footer-light with-border">
   <div class="lxbrow large center">
      <div class=" column width-12 widget center scroll-link cursorPointer pt-3">
         <div class="mx-auto" style=" height:30px;  width: 130px; ">
            <a  [routerLink]="['Main']" class="  p-0 m-0"  >
                <img src="./assets/images/Logo-SS-01.svg?20220912" class="p-0 m-0" alt="footer" loading="lazy"/>
            </a>
        </div>
      </div>
      <div class="column width-12">
         <div class="lxbrow flex two-columns-on-tablet">
            <div class="column width-4">
               <div class="widget center">
                  <h3 class="widget-title mb-30">{{'Company' | translate}}</h3>
                  <ul>
                     <li><a href="#" (click)="moverseElemento('AboutUs')" class="a scroll-link">{{'AboutUs' | translate}}</a></li>
                     <li><a [routerLink]="['/Terms']" class="scroll-link">{{'TermsAndConditions' | translate}}</a></li>
                     <li><a [routerLink]="['/Privacy']" class="scroll-link">{{'NoticeofPrivacy' | translate}}</a></li>
                     <li ><a [routerLink]="['/Tracking']" class="scroll-link">{{'Tracking' | translate}}</a></li>
                     <li><a [routerLink]="['/Main']" class="scroll-link">{{'NewSend' | translate}}</a></li>
                  </ul>
               </div>
            </div>
            <div class="column width-4">
               <div class="widget center">
                  <h3 class="widget-title mb-30">{{'Information' | translate}}</h3>
                  <ul>
                     <!-- href="#how-it-works" (click)="goHowItWorks()" -->
                     <li><a href="#" (click)="moverseElemento('HowItWork')" class="scroll-link">{{'HowItWork' | translate}}</a></li>
                     <!-- <li><a [routerLink]="['/Faq']"  class="scroll-link">{{'FrequentQuestion' | translate}}</a></li> -->
                     <li><a [routerLink]="['/Restrictions']" class="scroll-link">{{'restricciones' | translate}}</a></li>
                     <li><a [routerLink]="['/Login']" class="scroll-link ">{{'MyAccount' | translate}}</a></li>
               </ul>
               </div>
            </div>
            <div class="column width-4">
               <div class="widget center">
                  <h3 class="widget-title mb-30">{{'Contact' | translate}}</h3>
                  <ul>
                     <li><a href="mailto:support@shippingsmooth.com">
                        <i class="fa fa-envelope-o " aria-hidden="true"></i> support@shippingsmooth.com<br>
                     </a></li>
                     <li><a href="tel:+17865779153">
                        <i class="fa fa-phone-square" aria-hidden="true"></i> 1-786-577-9153<br>
                     </a></li>
                     <li><i class="fa fa-map-marker" aria-hidden="true"></i> {{'DirLb1' | translate}}</li>
                     <li>{{'DirLb2' | translate}}</li>
                     <!-- <li>{{'DirLb3' | translate}}</li> -->
                     <li><a href="sms:+17865779153?body=Hello,%20ShippingSmooth.com!%20Could%20you%20please%20assist%20me%20today%3F"><i class="fa fa-whatsapp" aria-hidden="true"></i>SMS</a></li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="widget center">
            <p><img src="./assets/images/satisfaction.webp" width="93" height="93" alt="static" loading="lazy"/><br><br>
            <img src="./assets/images/payment_logos.webp" width="119px" height="30px" alt="backdrop" loading="lazy"/></p>
         </div>
         <div class="social center pb-20">
            <a target="_blank" href="https://www.facebook.com/shippingsmooth/"> <img src="./assets/images/icon-face4.png" alt="facebook"  loading="lazy"/></a>&nbsp;&nbsp;
            <a target="_blank" href="https://www.instagram.com/shipppingsmooth/"> <img src="./assets/images/icon-insta4.png" alt="instagram" loading="lazy"/></a>&nbsp;&nbsp;
            <a target="_blank" href="https://twitter.com/shipppingsmooth"> <img src="./assets/images/icon-twitter4.png" alt="twitter"  loading="lazy"/></a>
         </div>
         <p class="copyright">&copy; 7LOC LLC. All Rights Reserved. 2023<br>Made with love by young coders</p>
         <i class="fa fa-caret-square-o-up scroll-link cursorPointer" aria-hidden="true" (click)="irInicio()"></i>
      </div>
   </div> 
</footer>