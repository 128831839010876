import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../Entities/Globals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  simpleAllowedRoutes = ['', 'Main', 'Simple', 'Tracking', 'Faq', 'Terms', 'Restrictions', 'CCCheckOut', 'Insurance', 'Confirmation'];
  mainNotAllowedRoutes = [];

  constructor(private router: Router, private actRoute: ActivatedRoute, private globals: Globals, private translate: TranslateService) { }

  checkVersion() {

    let version = this.getVersion();
    let route = this.getActualRoute();

    if (version == undefined || version == null || version == 'null') {
      this.setVersion();
    } else {
      if (route == '') {
        //version ? version : 'Main'
        this.router.navigate(['./Main']);
      }
    }
  }

  getActualRoute() {
    let route = this.router.url;
    route = route.split('/')[1];
    return route;
  }

  getVersion() {
    let actual;
    actual = localStorage.getItem('Version');
    return actual;
  }

  setVersion() {

    let route = this.getActualRoute();

    if (route != '' && (route == 'Main' || route == 'Simple')) {

      localStorage.setItem('Version', route);

    } else {

      localStorage.setItem('Version', 'Main');

    }

    this.checkVersion();

  }

  redirectAccordingToVersion() {

    let route = this.getActualRoute();

    if (this.getVersion() == 'Simple') {
      if (!this.simpleAllowedRoutes.includes(route)) {
        this.router.navigate(['./Simple']);

      }

    } else if (this.getVersion() == 'Main') {

      if (this.mainNotAllowedRoutes.includes(route)) {
        this.router.navigate(['./Main']);
      }

    }
  }

  getLocalLanguage() {
    let lang = localStorage.getItem("language");

    if (lang != null && lang != "" && lang != undefined) {
      return lang;
    } else {
      return "es";
    }
  }

  setLocalLanguage(lang) {
    localStorage.setItem("language", lang);
  }

  switchLanguage() {
    let lang = this.getLocalLanguage();
    this.setLocalLanguage(lang);
    
    this.globals.Order.Language.Code = lang;
    this.translate.use(lang);

  }


}
