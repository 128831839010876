import { OrderBE } from './OrderBE';

export class AngularLogBE {
    constructor() {
        //this.Order = new OrderBE();

        let date = new Date(); 
        let dtime = `${date.getFullYear()}-${(date.getMonth())+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;

        this.Date = dtime;
    }

    Date: string;
    ngException: string;
    MethodName: string;
    Order : string;
}