import { Component, ElementRef } from '@angular/core';
import { Globals } from '../../Entities/Globals';
import { SubscribersBE } from '../../Entities/SubscribersBE';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
//import { DEFAULT_VALUE_ACCESSOR } from '@angular/forms/src/directives/default_value_accessor';

@Component({
  selector: 'master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent {

  href: string = "";
  EmailSubs: string;
  NameSubs: string;
  msjCountries: string;
  isDevice: boolean = false;
  //subsForm: FormGroup;
  //message: string;

  subscriber = new SubscribersBE();
  constructor(public globals: Globals, private translate: TranslateService, private elementRef: ElementRef,
    private router: Router) {
    //this.message =  this.translate.instant("NoSpam");
    // this.globals.getData();
    // this.globals.getDataDevice();
    // this.isDevice = this.globals.isDevice;
    /* this.subsForm = fb.group({
       'Name': [null, Validators.required],
       'Email': [null, Validators.compose([Validators.required, Validators.email])]
     });*/
  }
  // ngOnInit() {
    

  //   if (this.router.url == "/Calc") {
  //     this.globals.saveDevice()
  //   }
   

  //   if (this.deviceService.isMobile()) {
  //     this.isDevice = true;
  //     this.globals.isDevice = true;
  //     this.globals.saveDevice()
  //     if (this.router.url == "/Main") {
  //       this.globals.saveDevice()
  //       //window.location.href = "m/"
  //     }
  //   } else {
  //     if (this.router.url == "/Main") {
  //       this.globals.saveDevice()
  //     }
  //     this.isDevice = false;
  //     this.globals.isDevice = false;
  //     this.globals.saveDevice()
  //   }
  // }


  // showSecondScreen() {
  //   this.globals.saveDevice()

  //   this.router.navigate(['./Calc']);
  // }

  // goToFirstScreen() {
  //   this.globals.saveDevice()

  //   this.router.navigate(['./Main']);
  // }


  /*SubscribeUser() {

    if (this.subsForm.valid) {
      this.subscriber.Name = this.NameSubs;
      this.subscriber.Email = this.EmailSubs;
      this.globals.executeMethod('Subscribers_InsertCommand', ({ subscribersBE: this.subscriber }), (data) => {
        if (data.Subscribers_InsertCommandResult.InsertResponseCode == 1) {
          this.message =  this.translate.instant("YouHaveBeenAdded");
          this.NameSubs = "";
          this.EmailSubs = "";
        } else if (data.Subscribers_InsertCommandResult.InsertResponseCode == 1000) {
          this.message =  this.translate.instant("YouAlreadySubscribed");
        } else {
          this.message = this.translate.instant("AnErrorOcurred");
        }
      });

    } else {
      this.globals.validateAllFormFields(this.subsForm); //{7}
    }
  }*/

}
