export class PromotionCodeBE {
    PromotionCodeId: number = 0;
    Code: string = "";
    Percent: number = 0;
    FixedValue: number = 0;
    From: string = "";
    To: string = "";
    Used: number = 0;
    MaxUsed: number = 0;
    MaxDiscount: number = 0;
    Response: number = 0;
    TotalValue: number = 0;
}
