<div class="cart-overview">
  <table class="table non-responsive rounded large mb-50">
    <tbody>
      <tr class="cart-item">
        <td colspan="2" class="product-details">
          <span>
            <span>
              <span class="product-title"
                >{{ "ShippingFrom" | translate }}
                {{
                  nationalOrder
                    ? (zipOrigination$ | async)?.Order_ZipcodeResult["ZipCity"]
                    : globals?.Order?.ZipOrigination?.ZipCity
                }}
                &rarr;
                {{
                  codISO2 == "CA"
                    ? country["Name"]
                    : nationalOrder
                    ? (zipDestination$ | async)?.Order_ZipcodeResult["ZipCity"]
                    : globals.Order.Package.CityBE.Name
                }}</span
              >
              <span
                class="label center small rounded bkg-orange-light color-white"
                style="line-height: 20px; font-size: 14px"
              >
                <div>
                  {{ "DeliveryIn" | translate }}
                  {{ globals.Order.SelectCarrier["DeliveryDays"] }}
                  {{ "BusinessDays" | translate }}
                </div>
                <!-- <div *ngIf="codISO2 == 'CO'">
                  {{ "EntregaDomicilioCO" | translate }}
                </div>
                <div *ngIf="codISO2 == 'MX'">
                  {{ "EntregaDomicilioMX" | translate }}
                </div>
                <div *ngIf="codISO2 == 'VE'">
                  {{ "EntregaDomicilioVE" | translate }}
                </div>
                <div *ngIf="codISO2 == 'DO'">
                  {{ "EntregaDomicilioDO" | translate }}
                </div>
                <div
                  *ngIf="
                    codISO2 != 'VE' &&
                    codISO2 != 'MX' &&
                    codISO2 != 'CO' &&
                    codISO2 != 'DO'
                  "
                >
                  {{ "EntregaDomicilioVE" | translate }}
                </div> -->
              </span>
            </span>
          </span>
        </td>
      </tr>
      <tr class="cart-item">
        <td class="product-details">
          <span>
            <span>
              <span class="product-title">{{ "Transporte" | translate }} </span>
              <span class="product-description">
                <em
                  >{{ "Weight" | translate }}: {{ OriginalWeight }}
                  {{ globals.Order.Package.MeasureWeight }}
                </em>
                <em>
                  {{ "Height" | translate }}: {{ Height | number : "1.1-1" }}
                  {{ measure }}
                </em>
                <em>
                  {{ "Width" | translate }}: {{ Width | number : "1.1-1" }}
                  {{ measure }}
                </em>
                <em>
                  {{ "Long" | translate }}: {{ Depth | number : "1.1-1" }}
                  {{ measure }}
                </em>
              </span>
            </span>
          </span>
        </td>
        <td class="product-subtotal right pt-5">
          <span class="amount"
            >${{
              globals.Order.CalculateResponse.Rate | number : "1.2-2"
            }}</span
          >
        </td>
      </tr>

      <tr *ngIf="globals.Order.TotalInsurance > 0" class="cart-item">
        <td class="product-details">
          <span>
            <span>
              <span class="product-title">{{ "Insurance" | translate }}</span>
              <span class="product-description">
                <em
                  >{{ "InsuredValue" | translate }} ${{
                    globals.Order.TotalInsurance | number : "1.2-2"
                  }}</em
                >
              </span>
            </span>
          </span>
        </td>
        <td class="product-subtotal right pt-2">
          <span class="amount"
            >${{
              globals.Order.CalculateResponse.Insurance | number : "1.2-2"
            }}</span
          >
        </td>
      </tr>

      <tr class="cart-item">
        <td class="product-details">
          <span>
            <span>
              <span class="product-title">{{ "Taxes" | translate }}</span>
              <span class="product-description">
                <em
                  >{{ "DeclaredValue" | translate }} ${{
                    globals.Order.DeclaredValue | number : "1.2-2"
                  }}</em
                ><em>
                  {{ "DutyCustoms" | translate }}
                </em>
              </span>
            </span>
          </span>
        </td>
        <!-- <td class="product-subtotal right pt-4" >
                    
                    <span class="amount">${{ globals.Order.CalculateResponse.Taxes | number : '1.2-2' }}</span>
                     *ngIf="codISO2=='CO'||codISO2=='VE'||codISO2=='MX'"
                </td> -->
        <td class="product-details right pt-4">
          <span *ngIf="codISO2 != 'CU'" class="product-description"> </span>
          <span *ngIf="codISO2 == 'CU'" class="amount">{{
            "smsImp" | translate
          }}</span>
          <!--  -->
          <!-- <span  *ngIf="(globals.Order.TotalTaxes>=100)&&(codISO2=='VE')"class="amount">{{'smsImp' | translate}}</span>
                    <span  *ngIf="(globals.Order.TotalTaxes<100)&&(codISO2=='VE')"class="amount">${{ globals.Order.CalculateResponse.Taxes | number : '1.2-2' }}</span> -->
        </td>
      </tr>
      <!-- <tr class="cart-order-total right">
                <td colspan="2">
                    {{'Total' | translate}}:&nbsp;&nbsp;
                    <span class="amount">$ {{ globals.Order.TotalValue }}</span>
                </td>
            </tr> -->
      <tr>
        <td
          colspan="3"
          class="pt-3"
          style="text-align: right; padding-left: 5px; padding-right: 25px"
        >
          <span *ngIf="showLabel">
            <span>{{ "Discount" | translate }}</span>
          </span>
          <input
            *ngIf="!showLabel"
            [(ngModel)]="objPromotion.Code"
            placeholder="{{ 'PromotionCode' | translate }}"
            class="form-element rounded small"
            id="txtPromotionCodE"
            style="
              width: 125px;
              padding-left: 5px;
              padding-right: 5px;
              height: 36px;
            "
          />
          &nbsp;
          <input
            *ngIf="!showLabel"
            type="button"
            (click)="validatePromoCode()"
            value="&rarr;"
            class="button rounded small bg-hover-green bkg-theme no-padding no-margin center pt-0"
            style="width: 40px; margin: 0px"
          />&nbsp;&nbsp;
          <!-- padding: 5px; -->
          <span>{{ sign }}{{ valueDiscount }} </span>
        </td>
      </tr>

      <tr class="cart-item right alert alert-danger" *ngIf="isMessage">
        <td colspan="3">
          {{ Message }}
        </td>
      </tr>
      <!-- cart-order-total -->
      <tr id="SubmitPayment" class="right cart-order-total">
        <td colspan="3">
          <!-- <strong style="color:chocolate;">STAMP:&nbsp;&nbsp;<span class="amount">$ {{ Stamp }}</span></strong>
                    &nbsp; -->
          <strong style="color: black"
            >{{ "Total" | translate }}:&nbsp;&nbsp;<span class="amount"
              >$ {{ totalWithDiscount | number : "1.2-2" }}</span
            ></strong
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
