export class ACContactBE {
    Id;
    FirstName: string = '';
    LastName: string = '';
    Email: string = '';
    Phone: string = '';
    ConversionValue: string = '';
    ConversionDateAndTime: string = '';
    ConversionCityState: string = '';
    ConversionCountry: string = '';
    ZipCodeUSA: string = '';
}
