import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../Entities/Globals';
import { PromotionCodeBE } from '../../Entities/PromotionCodeBE';
import { OrdenService } from '../../services/orden.service';
import { CalculateResponseBE } from '../../Entities/CalculateResponseBE';
import { ComplemtService } from '../../services/complemt.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageBE } from '../../Entities/PackageBE';
import { CountriesService } from '../../services/countries.service';
import { CountryBE } from '../../Entities/CountryBE';

@Component({
  selector: 'app-summaryExtend3',
  templateUrl: './summaryExtend3.component.html',
})
export class SummaryExtend3Component {
  // @Input() globalSummary: Globals;
  isMessage: boolean;
  Message: string;
  globals: any;
  valueDiscount: number = 0;
  totalWithDiscount: number = 0;
  objPromotion: PromotionCodeBE = new PromotionCodeBE();
  showLabel: boolean = false;
  OriginalWeight: string = '';
  Height: string = '';
  Width: string = '';
  Depth: string = '';
  sign: string = '-$';
  measure: string;
  codISO2 = '';

  country : string;
  Code: string = '';

  nationalOrder : boolean;

  zipOrigination$ : Observable<any>
  zipDestination$ : Observable<any>

  constructor(
    private global: Globals,
    private ordenService: OrdenService,
    private translate: TranslateService,
    public util: ComplemtService,
    private countryService : CountriesService
  ) {
    if (this.ordenService.isOrder) {
      this.global.Order = JSON.parse(this.ordenService.getInfoOrder());
      this.global.Order.PaymentMethodTotal = 'CC';
      this.codISO2 = this.global.Order.Package.IsoCountry;
      if (!this.global.Order.nationalOrder)
        this.codISO2 = this.global.Order.Package.CityBE.CountryBE.ISO2;
        
    }
    this.init();
    this.getIsoCountry();
  }

  getZipCodes() {
    const zipCodes : PackageBE = this.globals.Order.Package;
    this.zipOrigination$ = this.util.executeMethod('Order_Zipcode', { codigo: zipCodes['ZipOrigination']  })
    this.zipDestination$ = this.util.executeMethod('Order_Zipcode', { codigo: zipCodes['ZipDestination']  })
  }

  private getIsoCountry(){
    this.country = JSON.parse(this.countryService.getInfoCountryBE()).find(
      (value: CountryBE) => this.codISO2 == value['ISO2']
    );
  }

  init() {
    this.isMessage = false;
    let obj = Object.assign({}, this.global);
    this.globals = obj;

    this.nationalOrder = this.globals.Order.nationalOrder;

    this.getZipCodes();
    this.globals.Order.TotalValue = this.global.Round(
      this.globals.Order.TotalValue,
      2
    );
    this.globals.Order.Package.OriginalWeight = this.global.Order.Packages[0]
      .OriginalWeight
      ? this.global.Order.Packages[0].OriginalWeight
      : 0;
    if (this.globals.Order.Package.Pounds != undefined) {
      if (
        this.globals.Order.PromotionCode != null &&
        this.globals.Order.PromotionCode.Response == 1
      ) {
        if (this.globals.Order.PromotionCode.Code.includes('COD')) {
          this.globals.Order.Discount =
            this.globals.Order.PromotionCode.Percent;
          this.valueDiscount = +this.global.Round(
            this.globals.Order.CalculateResponse.Rate *
              (this.globals.Order.Discount / 100),
            2
          );
          this.totalWithDiscount =
            this.globals.Order.CalculateResponse.Rate + this.valueDiscount;
          this.totalWithDiscount = +this.global.Round(
            this.totalWithDiscount +
              this.globals.Order.CalculateResponse.Taxes +
              this.globals.Order.CalculateResponse.Insurance,
            2
          );
          this.sign = '+$';
        } else {
          if (this.globals.Order.PromotionCode.Percent > 0) {
            this.globals.Order.Discount =
              this.globals.Order.PromotionCode.Percent;
            this.valueDiscount = +this.global.Round(
              this.globals.Order.CalculateResponse.Rate *
                (this.globals.Order.Discount / 100),
              2
            );
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
                this.globals.Order.CalculateResponse.Taxes +
                this.globals.Order.CalculateResponse.Insurance,
              2
            );
          } else {
            this.globals.Order.Discount = 0;
            this.valueDiscount = this.globals.Order.PromotionCode.FixedValue;
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
                this.globals.Order.CalculateResponse.Taxes +
                this.globals.Order.CalculateResponse.Insurance,
              2
            );
          }
          this.sign = '-$';
        }
      } else {
        this.valueDiscount = +this.global.Round(
          this.globals.Order.CalculateResponse.Rate *
            (this.globals.Order.Discount / 100),
          2
        );
        this.totalWithDiscount =
          this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
        this.totalWithDiscount = +this.global.Round(
          this.totalWithDiscount +
            this.globals.Order.CalculateResponse.Taxes +
            this.globals.Order.CalculateResponse.Insurance,
          2
        );
      }
    }
    /*
        if (this.globals.Order.Step == "3") {
            this.showLabel = true;
        }

        if (this.globals.Order.Step == "5") {
            this.showLabel = true;
            this.globals.Order.TotalValue = this.global.Order.CalculateResponse.Rate + this.global.Order.CalculateResponse.Insurance + this.global.Order.CalculateResponse.Taxes
            this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue,2)
           
            this.global.resetGeneralData();
        }
        */
    var inchesConvertion = 2.54;
    this.measure = this.globals.Order.Package.Measure.slice(0, 2);
    if (this.globals.Order.Package.MeasureWeight != 'Lb') {
      this.OriginalWeight = (
        this.globals.Order.Packages[0].Weight / 2.2046
      ).toString();
    } else {
      this.OriginalWeight = this.globals.Order.Packages[0].Weight.toString();
    }
    if (this.globals.Order.Package.Measure != 'Inches') {
      this.Depth = (
        this.globals.Order.Packages[0].Depth * inchesConvertion
      ).toString();
      this.Height = (
        this.globals.Order.Packages[0].Height * inchesConvertion
      ).toString();
      this.Width = (
        this.globals.Order.Packages[0].Width * inchesConvertion
      ).toString();
    } else {
      this.Depth = this.globals.Order.Packages[0].Depth.toString();
      this.Height = this.globals.Order.Packages[0].Height.toString();
      this.Width = this.globals.Order.Packages[0].Width.toString();
    }
  }

  validatePromoCode() {
    this.objPromotion.TotalValue = this.globals.Order.CalculateResponse.Rate;

    this.global.executeMethod(
      'PromotionCode_SelectCommand',
      { promotion: this.objPromotion },
      (x) => {
        this.objPromotion = x.PromotionCode_SelectCommandResult;
        if (this.objPromotion.Response == 1) {
          this.isMessage = false;
          if (this.objPromotion.Code.includes('COD')) {
            this.globals.Order.Discount = this.objPromotion.Percent;
            this.valueDiscount = +this.global.Round(
              this.globals.Order.CalculateResponse.Rate *
                (this.globals.Order.Discount / 100),
              2
            );
            this.totalWithDiscount =
              this.globals.Order.CalculateResponse.Rate + this.valueDiscount;
            this.totalWithDiscount = +this.global.Round(
              this.totalWithDiscount +
                this.globals.Order.CalculateResponse.Taxes +
                this.globals.Order.CalculateResponse.Insurance,
              2
            );
            this.sign = '+$';
          } else {
            if (this.objPromotion.Percent > 0) {
              this.globals.Order.Discount = this.objPromotion.Percent;
              this.valueDiscount = +this.global.Round(
                this.globals.Order.CalculateResponse.Rate *
                  (this.globals.Order.Discount / 100),
                2
              );
              this.totalWithDiscount =
                this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
              this.totalWithDiscount = +this.global.Round(
                this.totalWithDiscount +
                  this.globals.Order.CalculateResponse.Taxes +
                  this.globals.Order.CalculateResponse.Insurance,
                2
              );
            } else {
              this.globals.Order.Discount = 0;
              this.valueDiscount = this.objPromotion.FixedValue;
              this.totalWithDiscount =
                this.globals.Order.CalculateResponse.Rate - this.valueDiscount;
              this.totalWithDiscount = +this.global.Round(
                this.totalWithDiscount +
                  this.globals.Order.CalculateResponse.Taxes +
                  this.globals.Order.CalculateResponse.Insurance,
                2
              );
            }
            this.sign = '-$';
          }
          this.global.Order.PromotionCode = this.objPromotion;
          this.global.saveData();
        } else if (this.objPromotion.Response == -1) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeInvalid');
        } else if (this.objPromotion.Response == -100) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeExpired');
        } else if (this.objPromotion.Response == -200) {
          this.isMessage = true;
          this.Message = this.translate.instant('CodeMaxUse');
        }
      }
    );
  }

  getGlobalsData() {
    this.global.getData();
    this.init();
  }
}

/*
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BeneficiaryBE } from '../Entities/BeneficiaryBE';
import { Globals } from '../Entities/Globals';
import { OrderBE } from '../Entities/OrderBE';
import { Observable } from 'rxjs';
import { PackageBE } from '../Entities/PackageBE';
import { PromotionCodeBE } from '../Entities/PromotionCodeBE';
import { ComplemtService } from '../Utils/complemt.service';
import { LanguageService } from '../Utils/language.service';
import { LogService } from '../Utils/log.service';
import { OrdenService } from '../Utils/orden.service';

@Component({
    selector: 'app-summaryExtend3',
    templateUrl: './summaryExtend3.component.html'
})

export class SummaryExtend3Component {
    // @Input() globalSummary: Globals;
    tmpOrder:OrderBE=new OrderBE();
    isMessage: boolean;
    mensajeCode: string;
    Message: string;
    globals: any;
    valueDiscount: number = 0
    totalWithDiscount: number = 0
    objPromotion: PromotionCodeBE = new PromotionCodeBE();
    showLabel: boolean = false;
    bandPromocion: boolean = false;
    bandErrorPromocion: boolean = false;
    escuchaLenguaje$:Observable<any>;
    sign: string = '-$';

    measure: string;

    constructor(private global: Globals, 
                private languageService:LanguageService,
                private translate: TranslateService,
                private ordenService:OrdenService,
                private router: Router,
                public util:ComplemtService,
                private logService: LogService, //SERVICIO DE REGISTRO DE LOG
                public toastr: ToastrService
                ) {
                
                  
                
                    
//        this.init();
        
        this.mensajeCode="Campo invalido";

        // this.tmpOrder.TotalTaxes
        // this.tmpOrder.CalculateResponse.Insurance
        // this.tmpOrder.PromotionCode.TotalValue
        // this.tmpOrder.Package.Pounds
    }
    ngOnInit(): void {
        this.escuchaLenguaje$=this.languageService.getLenguaje$();
        this.escuchaLenguaje$.subscribe(res=>{
            this.mensajeCode=this.translate.instant("InvalidField");
        });
        if(this.ordenService.isOrder){
            //console.log(JSON.parse(this.ordenService.getInfoOrder()));
            this.tmpOrder= JSON.parse(this.ordenService.getInfoOrder());
        }else {this.router.navigate(['./Calc']);}
    }


    // init() {
    //     this.isMessage = false
    //     let obj = Object.assign({}, this.global);
    //     this.globals = obj;
    //     this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue, 2);

    //     this.globals.Order.Package.OriginalWeight = this.global.Order.Packages[0].OriginalWeight ? this.global.Order.Packages[0].OriginalWeight : 0;

    //     if (this.globals.Order.Package.Pounds != undefined) {
    //         if (this.globals.Order.PromotionCode != null && this.globals.Order.PromotionCode.Response == 1) {
    //             if (this.globals.Order.PromotionCode.Code.includes('COD')) {
    //                 this.globals.Order.Discount = this.globals.Order.PromotionCode.Percent
    //                 this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
    //                 this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate + this.valueDiscount
    //                 this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
    //                 this.sign = '+$';
    //             } else {
    //                 if (this.globals.Order.PromotionCode.Percent > 0) {
    //                     this.globals.Order.Discount = this.globals.Order.PromotionCode.Percent
    //                     this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
    //                     this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
    //                     this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
    //                 } else {
    //                     this.globals.Order.Discount = 0
    //                     this.valueDiscount = this.globals.Order.PromotionCode.FixedValue
    //                     this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
    //                     this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
    //                 }
    //                 this.sign = '-$';

    //             }
    //         } else {
    //             this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
    //             this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
    //             this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
    //         }
    //     }
    //     
    //     if (this.globals.Order.Step == "3") {
    //         this.showLabel = true;
    //     }

    //     if (this.globals.Order.Step == "5") {
    //         this.showLabel = true;
    //         this.globals.Order.TotalValue = this.global.Order.CalculateResponse.Rate + this.global.Order.CalculateResponse.Insurance + this.global.Order.CalculateResponse.Taxes
    //         this.globals.Order.TotalValue = this.global.Round(this.globals.Order.TotalValue,2)
           
    //         this.global.resetGeneralData();
    //     }
    //     

    //     this.measure = this.globals.Order.Package.Measure.slice(0, 2);
    // }

    cambiarCode(){
        let tempCode=this.objPromotion.Code;
        this.objPromotion=new PromotionCodeBE();
        this.objPromotion.Code=tempCode;
        this.bandPromocion=false;
        this.valueDiscount=0;
        this.tmpOrder.PromotionCode=this.objPromotion;
        this.ordenService.setInfoOrder(JSON.stringify(this.tmpOrder));
    }
    validatePromoCode() {
        //this.objPromotion.TotalValue
        this.cambiarCode();
        if (this.util.isErrorCampoTexto(this.objPromotion.Code,3)){
            this.toastr.error(this.translate.instant("RequiredField"), this.translate.instant("System"));
            this.mensajeCode=this.translate.instant("InvalidField");
            document.getElementById('txtPromotionCodE').focus();
            this.bandErrorPromocion=true;
            return;
        }else this.bandErrorPromocion=false;
        this.util.executeMethod('PromotionCode_SelectCommand', { promotion: this.objPromotion }).subscribe( (data) => {
            //console.log(data);
            if (data.PromotionCode_SelectCommandResult.Response == 1 || (data.PromotionCode_SelectCommandResult.Response == -1&&data.PromotionCode_SelectCommandResult.PromotionCodeId>1)) {
               // console.log("paso a calular el valor");
               this.objPromotion=data.PromotionCode_SelectCommandResult;
                this.bandPromocion=true;
                if(this.objPromotion.Percent){
                    this.valueDiscount = parseFloat(this.global.Round((this.tmpOrder.CalculateResponse.Rate * (this.objPromotion.Percent / 100)), 2));
                }else {
                    if(this.objPromotion.FixedValue){
                        this.valueDiscount = parseFloat(this.global.Round(( this.objPromotion.FixedValue), 2));
                    }
                }
                this.tmpOrder.PromotionCode=this.objPromotion;
                this.ordenService.setInfoOrder(JSON.stringify(this.tmpOrder));
                //this.
                // this.globals.Order.Discount = this.objPromotion.Percent
                // 
                // this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate + this.valueDiscount
                // this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
                // this.sign = '+$';
            }else{
                if (data.PromotionCode_SelectCommandResult.Response == -100) {
                    this.toastr.error(this.translate.instant("CodeExpired"), this.translate.instant("System"));
                    this.mensajeCode=this.translate.instant("CodeExpired");
                } else if (data.PromotionCode_SelectCommandResult.Response == -200) {
                    this.toastr.error(this.translate.instant("CodeMaxUse"), this.translate.instant("System"));
                    this.mensajeCode=this.translate.instant("CodeMaxUse");
                }else{
                    this.toastr.error(this.translate.instant("SmsInvalidField"), this.translate.instant("System"));
                    this.mensajeCode=this.translate.instant("InvalidField");
                }
                document.getElementById('txtPromotionCodE').focus();
                this.bandErrorPromocion=true;
                return;
            }
        },
        err => {
          console.log("ERROR Servidor:'"+JSON.stringify(err)+"'")
          this.logService.insertLocalLog(err, 'Total  - Error: PromotionCode_SelectCommand' , ({ promotion: this.objPromotion }));
        });

        // this.objPromotion.TotalValue = this.globals.Order.CalculateResponse.Rate;
        // this.global.executeMethod("PromotionCode_SelectCommand", { promotion: this.objPromotion }, (x) => {
        //     this.objPromotion = x.PromotionCode_SelectCommandResult
        //     if (this.objPromotion.Response == 1) {
        //         this.isMessage = false
        //         if (this.objPromotion.Code.includes('COD')) {
        //             this.globals.Order.Discount = this.objPromotion.Percent
        //             this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
        //             this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate + this.valueDiscount
        //             this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
        //             this.sign = '+$';
        //         } else {
        //             if (this.objPromotion.Percent > 0) {
        //                 this.globals.Order.Discount = this.objPromotion.Percent
        //                 this.valueDiscount = +this.global.Round((this.globals.Order.CalculateResponse.Rate * (this.globals.Order.Discount / 100)), 2)
        //                 this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
        //                 this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
        //             } else {
        //                 this.globals.Order.Discount = 0
        //                 this.valueDiscount = this.objPromotion.FixedValue
        //                 this.totalWithDiscount = this.globals.Order.CalculateResponse.Rate - this.valueDiscount
        //                 this.totalWithDiscount = +this.global.Round(this.totalWithDiscount + this.globals.Order.CalculateResponse.Taxes + this.globals.Order.CalculateResponse.Insurance, 2)
        //             }
        //             this.sign = '-$';
        //         }
        //         this.global.Order.PromotionCode = this.objPromotion
        //         this.global.saveData()
        //     } else if (this.objPromotion.Response == -1) {
        //         this.isMessage = true
        //         this.Message = this.translate.instant("CodeInvalid");
        //     } else if (this.objPromotion.Response == -100) {
        //         this.isMessage = true
        //         this.Message = this.translate.instant("CodeExpired");
        //     } else if (this.objPromotion.Response == -200) {
        //         this.isMessage = true
        //         this.Message = this.translate.instant("CodeMaxUse");
        //     }
        // });
    }
    // getGlobalsData() {
    //     this.global.getData();
    //     this.init();

    // }


}
*/
