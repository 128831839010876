import { Component } from '@angular/core';
import { Globals } from './shared/Entities/Globals';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from "@angular/router";
import { VersionService } from './shared/services/version.service';
import { LogService } from './shared/services/log.service';
import { IpService } from './shared/services/ip.service';
import { SegmentService } from './shared/services/segment.service';
import { LanguageService } from './shared/services/language.service';


declare const gtag: Function; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  title = 'ShippingSmooth.com';
  page = '';
  regionName = '';
  bandPreload: boolean = true;
  constructor(
    private globals: Globals,
    private translate: TranslateService,
    private router: Router,
    private vs: VersionService,
    private logService: LogService,
    private ip: IpService,
    private segmentService: SegmentService,
    private languageService: LanguageService
  ) {
    //Traduce la pagina por defecto en 'es'
    translate.setDefaultLang('en');
    languageService.setLenguaje('en');
    this.bandPreload = false;

    /*
    (<any>window).addEventListener("beforeunload", function (e) {
      logService.sendLocalLogToServer();
    });

    ip.checkLocalClientIP();
*/
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      window.scrollTo(0, 0);
      window.scroll(0, 0);
    });
  }

  // ngAfterViewInit(): void {
  //   this.loadScript('https://apps.elfsight.com/p/platform.js')
  // }

  // public loadScript(url: string): void {
  //   const body = <HTMLDivElement>document.body;
  //   const script = document.createElement('script');
  //   script.innerHTML = '';
  //   script.src = url;
  //   script.async = false;
  //   script.defer = true;
  //   body.appendChild(script);
  // }

  onActivate(event) {
    this.logService.checkLocalLog();
    //this.vs.checkVersion();
    //this.vs.redirectAccordingToVersion();
    this.segmentService.setGclid();
    this.segmentService.setGclidFromURL();
    window.scroll(0, 0);
    window.scrollTo(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
    this.vs.switchLanguage();
  }
  /*
  switchLanguage(language: string) {
    this.translate.use(language);
  }*/
}
