import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  //value: unknown, ...args: unknown[]
  
  transform(items: any[], field : string, value : string): any[] {
    if (!items) return [];
      if (!value || value.length == 0) return items;
      // return items.filter(it => 
      //   it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1).length!=0?items.filter(it => 
      //     it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1):items;
      // console.log("field",field);
      // console.log("value",value);
      return items.filter(it => 
        it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1).concat(items.filter(it => 
          it[field].toLowerCase().indexOf(value.toLowerCase()) !=0));
      
  }

}
