        <div class="lxbrow pt-0 pl-2 pr-2 " > 
            <!-- [options:{{options}} || {{pais}} ||{{id}}] -->
        <label *ngIf="(options!='CO'&&options!='MX'&&options!='VE'&&options!='DO'&&options!='CU')">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/{{id}}.svg" width="20px"  loading="lazy"/> {{pais}}</span>
                <ul>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasSKY3' | translate}}</li>
                </ul>
            </label>
            
            <label *ngIf="options=='CU'">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/348.svg" width="20px"  loading="lazy"/> CUBA</span>
                <ul>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU1' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU2' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU3' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU4' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU5' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU6' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCU7' | translate}}</li>
                </ul>
            </label>
            <label *ngIf="options=='CO'">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/2.svg" width="20px"  loading="lazy"/> COLOMBIA</span>
                <ul>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO1' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO2' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO3' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO4' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO5' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasCO6' | translate}}</li>
                </ul>
            </label>
            <label *ngIf="options=='DO'">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/506.svg" width="20px"  loading="lazy"/> DOMINICAN REPUBLIC </span>
                <ul>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasDO1' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasDO2' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasDO3' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}" >{{'restriccionaAduanasDO4' | translate}}</li>
                </ul>
            </label>
            <label *ngIf="options=='MX'">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/6.svg" width="20px"  loading="lazy"/> MEXICO</span>
                <ul>
                    <li  [ngClass]="{'contenidoModal':modal}">{{'restriccionaAduanasMX1' | translate}}</li>
                    <li  [ngClass]="{'contenidoModal':modal}">{{'restriccionaAduanasMX2' | translate}}</li>
                </ul>
            </label>
            <label *ngIf="options=='VE'">
                <span class="label rounded bkg-grey color-white" [ngClass]="{'contenidoModal':modal}"><img src="./assets/images/flags/9.svg" width="20px"  loading="lazy"/> VENEZUELA</span>
                <ul>
                    <li [ngClass]="{'contenidoModal':modal}">{{'restriccionaAduanasVE1' | translate}}</li>
                </ul>
            </label>
            <p  [ngClass]="{'contenido2Modal':modal}">{{'restrictext16' | translate}}</p>
        </div>   