import { CityBE } from "./CityBE";


export class PackageBE {

  constructor() {
    this.CityBE = new CityBE();
  }
  ZipOrigination: string
  IsoCountry: string;
  ZipDestination: string;
  Pounds: string;
  Ounces: string;
  Container: string;
  Size: string;
  Zone: string;
  Postage: any;
  ID: string;
  Width: number;
  Length: number;
  Height: number;
  Girth: number;
  Measure: string;
  MeasureWeight: string;
  CityBE = new CityBE();
}