import { CityBE } from "./CityBE";
import { LanguageBE } from "./LanguageBE";
import { NavigationClientBE } from "./NavigationClientBE";


export class CustomerBE {
  
  constructor() {
    this.CityBE = new CityBE();
    this.Language = new LanguageBE();
    this.NavigationClient = new NavigationClientBE();
  }

  customerBE: {
    
  }
  Id: number;
  Name: string="";
  LastName: string="";
  Address: string="";
  Address2: string="";
  ZipCode: string="";
  Phone: string="";
  MobilePhone: string="";
  Email: string="";
  EmailConfirmation: string="";
  Password: string="";
  PasswordConfirmation: string="";
  OldPassword: string="";
  JoinDate: string;
  IsEnabled: boolean;
  FailedLoginCount: number;
  CityBE = new CityBE();
  ResponseCode: number;
  IsLogged: boolean;
  IsVerified: boolean;
  Language: LanguageBE;
  NavigationClient: NavigationClientBE;
  Role: number;
  createAccount: boolean = true;
}